<template>
  <div class="globalfooter">
    <div class="line"></div>

    <div class="realfooter">
      <div class="">
        <section>
          <b-row>
            <b-col>
              <img
                class="mb-1"
                height="25px"
                src="@/app/assets/img/bpmnstorm_small.png"
              />
              <b class="ml-1"> {{ $t("stormbpmn") }} </b>

              <small>
                <span v-html="currentVersion" />
              </small>

              <p
                style="color: #77a3f0; cursor: pointer"
                v-show="isAuthenticated"
                class="changelogfy-widget"
              >
                <b> {{ $t("whats-new") }}</b>
              </p>

              <b>
                <p class="psmall">
                  <a target="_blank" href="https://bpmn2.freshdesk.com/">
                    {{ $t("get-help") }}
                  </a>
                </p>
              </b>

              <a target="_blank" href="https://t.me/bpmn2ru">
                <img
                  class="telegram-logo"
                  src="@/app/assets/img/icons8-telegram.svg"
                />
              </a>
              <a
                class="ml-1"
                target="_blank"
                href="https://www.youtube.com/channel/UCE6G89s6-3vL8mydK0p7Drg"
              >
                <img
                  class="telegram-logo"
                  src="@/app/assets/img/icons8-youtube.svg"
                />
              </a>
              <br />
            </b-col>
            <b-col>
              <b>{{ $t("Product") }}</b>

              <p class="pfooter">
                <a href="/modeling-in-bpmn"> {{ $t("process-modeling") }} </a>
              </p>
              <p class="psmall">
                <a href="/architecture-bpmn"> {{ $t("Architecture") }} </a>
              </p>
              <p class="psmall">
                <a href="/manage-camunda"> {{ $t("Camunda-manage") }} </a>
              </p>
              <p class="psmall">
                <a
                  target="_blank"
                  href="https://stormbpmn.changelogfy.com/changelog/en"
                >
                  {{ $t("changelog") }}
                </a>
              </p>

              <b>{{ $t("footer-solutions") }}</b>

              <p class="pfooter">
                <a href="/enterprise"> {{ $t("Enterprise-footer") }} </a>
              </p>
              <p class="psmall">
                <a href="/smb"> {{ $t("small-business") }} </a>
              </p>
              <p class="psmall">
                <a href="/personal"> {{ $t("personal-use") }} </a>
              </p>
              <p class="psmall">
                <a href="/nonprofits"> {{ $t("Nonprofits") }} </a>
              </p>
              <p class="psmall">
                <a href="/education"> {{ $t("Education") }} </a>
              </p>
            </b-col>
            <b-col
              ><b>{{ $t("learn") }}</b>
              <p class="pfooter">
                <a href="/marketing">
                  <img
                    class="telegram-logo"
                    src="@/app/assets/img/icons8-youtube.svg"
                  />
                  <b>{{ $t("online-master-class") }} </b></a
                >
              </p>
              <p class="psmall">
                <a target="_blank" href="/bpmn/free-course">
                  {{ $t("free-email-course") }}
                </a>
              </p>
              <p class="psmall">
                <a target="_blank" href="https://docs.stormbpmn.com/">
                  {{ $t("Knowledge-base") }}
                </a>
              </p>
              <p class="psmall">
                <a href="/bpmn/elements"> {{ $t("BPMN-elements") }} </a>
              </p>
              <p class="psmall">
                <a
                  target="_blank"
                  href="https://www.youtube.com/playlist?list=PLJHWAyPXCgb-rfjwUmEhNuPV6wIwRRscg"
                >
                  {{ $t("BPMN-course") }}
                </a>
              </p>
              <p class="psmall">
                <a target="_blank" href="https://bpmn2.ru/blog">
                  {{ $t("Blog") }}
                </a>
              </p>
              <p class="psmall">
                <a target="_blank" href="https://t.me/bpmn2group">
                  <img
                    class="telegram-logo"
                    src="@/app/assets/img/icons8-telegram.svg"
                  />
                  {{ $t("community") }}
                </a>
              </p>
              <p class="psmall">
                <a target="_blank" href="/marketing/pma">
                
                  {{ $t("pma") }}
                </a>
              </p>

              <b>
                <a style="color: #77a3f0; cursor: pointer">
                  <StartFromTemplate /> </a
              ></b>
            </b-col>
            <b-col
              ><b>{{ $t("Get-started") }}</b>
              <p class="pfooter">
                <a href="/compare/drawio"> {{ $t("compare-drawio") }} </a>
              </p>
              <p class="psmall">
                <a href="/compare/bizagi">
                  {{ $t("compare-bizagi-modeler") }}
                </a>
              </p>
              <p class="psmall">
                <a href="/compare/camunda-modeler">
                  {{ $t("compare-camunda-modeler") }}
                </a>
              </p>
              <p class="psmall">
                <a href="/compare/miro"> {{ $t("compare-miro") }} </a>
              </p>

              <b>{{ $t("Resourses") }}</b>

              <p class="pfooter">
                <a href="/pricing"> {{ $t("pricing") }} </a>
              </p>
              <p class="psmall">
                <a target="_blank" href="mailto:team@bpmn2.ru">
                  {{ $t("emails-us") }}
                </a>
              </p>
              <p class="psmall">
                <a href="/license"> {{ $t("Tearms") }} </a>
              </p>
              <p class="psmall">
                <a target="_blank" href="https://stormbpmn.freshstatus.io/">
                  {{ $t("status-page") }}
                </a>
              </p>
            </b-col>
          </b-row>
        </section>
        <hr />
        <span class="text-muted"> {{ $t("we-dont-sale-or-share") }} </span>
        <br />
        <small class="text-muted"> {{ $t("legal-info") }} </small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
  components: {
    StartFromTemplate: () =>
      import("@/features/diagram-templates/start-from-template.vue"),
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    currentVersion() {
      try {
        return this.$store.getters.getSettings.find(
          (x) => x.name === "stormVersion"
        ).value;
      } catch {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}

.line {
  border-top: 2px solid #f2f2f2;
}

.realfooter {
  margin-top: 50px;
  max-width: 1600px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.globalfooter {
  margin-top: 0px;
  background-color: #e9ecef6e;
}

.telegram-logo {
  width: 25px;
}

.pfooter {
  margin-top: 10px;
}

.psmall {
  margin-top: -1rem;
}
</style>
